import React, {Suspense, lazy, useEffect} from "react"
import {  Switch, Route,Redirect} from "react-router-dom"
import Logo from "./images/logo_english.svg"
import Header from "./sections/header"
import Navbar from "./sections/navbar"
import { ClipLoader } from "react-spinners";
import fakeAuth from "./authenticate"
import { useLocation } from "react-router";
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      fakeAuth.isAuthenticated === true
        ? 
        
        <Component {...props} />
        : <Redirect to='/' />
    )}
    
    />
    
  )

  const Login = lazy(() => import("./pages/login"))
  const HomeBeforeLogin = lazy(() => import("./pages/home-page-before-login/index"))
  const Kitchenlisting = lazy(() => import("./pages/kitchenlisting"))
  const Kitchendetail = lazy(() => import("./pages/kitchendetail"))
  const KitchendetailCopy = lazy(() => import("./pages/kitchendetail copy"))
  const Orderdetails = lazy(() => import("./pages/orderdetails"))
  const PaymentThankyou = lazy(() => import("./pages/paymentThankyou"))
  const PaymentThankyouOtherOrder = lazy(() => import("./pages/paymentThankyouOtherOrder"))
  const HomePageAfterLogin = lazy(() => import("./pages/home-page-after-login/index"))
  const Mytransactions = lazy(() => import("./pages/mytransactions"))
  const ServiceTickets = lazy(() => import("./pages/serviceTickets/ServiceTickets"))
  const ServiceTicketDetails = lazy(() => import("./pages/serviceTickets/serviceTicketDetails/ServiceTicketDetails"))
  const privacyPolicy = lazy(() => import("./pages/privacyPolicy"))
  const Getyourkitchen = lazy(() => import("./pages/getyourkitchen"))
  const Yourkitchenlasts = lazy(() => import("./pages/yourkitchenlasts"))
  const Ourkitchen = lazy(() => import("./pages/ourkitchen"))
  const Productdetail = lazy(() => import("./pages/productdetail"))
  const Buyaproduct = lazy(() => import("./pages/buyaproduct"))
  const Mycart = lazy(() => import("./pages/mycart"))
  const Faq = lazy(() => import("./pages/faq"))
  const Howcreative = lazy(() => import("./pages/howcreative"))
  const TermsConditions = lazy(() => import("./pages/terms&conditions"))
  const Contactus = lazy(() => import("./pages/contactus"))
  const Supportcenter = lazy(() => import("./pages/supportcenter"))
  const Niulivideos = lazy(() => import("./pages/niulivideos"))
  const Appliancedetails = lazy(() => import("./pages/appliancedetail"))
  const DataDeletionPage = lazy(() => import("./pages/dataDeletion"))
  const installmentPlanPage = lazy(() => import("./pages/installment-plan"))
  const ReferralPage = lazy(() => import("./pages/referral"))
  // const ReferralRedirectPage = lazy(() => import("./pages/referral-redirect"))
  // const Campaigns = lazy(() => import("./pages/campaign"))
  
  const nuiliLogoStyle={
    position: "absolute",
    top: "45%",
    left: "48%"
  }
  
export default function Router() {

  const search=useLocation().search;

  useEffect(()=>{
    const utm_source = new URLSearchParams(search).get('utm_source');
    const utm_medium = new URLSearchParams(search).get('utm_medium');
    const utm_campaign = new URLSearchParams(search).get('utm_campaign');
    const utm_term = new URLSearchParams(search).get('utm_term');
    const utm_content = new URLSearchParams(search).get('utm_content');
    
    if(!localStorage.getItem("utm_source") && utm_source){
      localStorage.setItem("utm_source",utm_source)
    }
    if(!localStorage.getItem("utm_medium") && utm_medium){
      localStorage.setItem("utm_medium",utm_medium)
    }
    if(!localStorage.getItem("utm_campaign") && utm_campaign){
      localStorage.setItem("utm_campaign",utm_campaign)
    }
    if(!localStorage.getItem("utm_term") && utm_term){
      localStorage.setItem("utm_term",utm_term)
    }
    if(!localStorage.getItem("utm_content") && utm_content){
      localStorage.setItem("utm_content",utm_content)
    }
  },[])

  return (
    
       <main>
       
       <Suspense fallback={
            <div  style={nuiliLogoStyle}>
              <div style={{width: "100%", float: "left"}}>
              <img src={Logo} style={{width: "150px"}} alt="Niuli" />
              </div> 
              <div style={{width: "100%", textAlign: "center", paddingTop: "50px"}}>
              <ClipLoader
								color={"#242E49"}
								loading={true}
								/>
                </div>
            </div>
       }>
            {/* <Header /> */}
            {
              window.location.pathname!=="/data-deletion"
              ?
              <Navbar/>
              :
              <></>
            }
            
            <Switch>
            <Route path="/login" component={Login} exact />
            <Route path="/login/:influencer" component={Login} exact />
            <Route path="/" component={HomeBeforeLogin} exact />
            <Route  path="/kitchenlisting/:id/:name" component={Kitchenlisting}  />
            <Route path="/kitchendetail/:categoryid/:designid" component={Kitchendetail} exact />
            <Route path="/orderdetails/:id" component={Orderdetails} exact />
            <Route path="/home" component={HomePageAfterLogin} exact />
            <Route path="/paymentThankyou" component={PaymentThankyou}  />
            <Route path="/paymentThankyouOtherOrders" component={PaymentThankyouOtherOrder} />
            <Route path="/mytransactions" component={Mytransactions}  />
            <Route path="/service-tickets/:ticketId" component={ServiceTicketDetails}  />
            <Route path="/service-tickets" component={ServiceTickets}  />
            <Route path="/privacy-policy" component={privacyPolicy} />
            <Route path="/KitchendetailCopy/:categoryid/:designid" component={KitchendetailCopy}/>
            <Route path="/getyourkitchen" component={Getyourkitchen} />
            <Route path="/yourkitchenlasts" component={Yourkitchenlasts} />
            <Route path="/ourkitchen" component={Ourkitchen} />
            <Route path="/productdetail" component={Productdetail} />
            <Route path="/buyaproduct" component={Buyaproduct} />
            <Route path="/mycart" component={Mycart} />
            <Route path="/faq" component={Faq} />
            <Route path="/howcreative" component={Howcreative} />
            <Route path="/terms&Conditions" component={TermsConditions} />
            <Route path="/contactus" component={Contactus} />
            <Route path="/supportcenter" component={Supportcenter} />
            <Route path="/niulivideos" component={Niulivideos} />
            <Route path="/appliancedetails/:id" component={Appliancedetails} exact />

            {/* <Route path="/campaign" component={Campaigns} exact /> */}
            <Route path="/installment-plan" component={installmentPlanPage} exact />
            <Route path="/data-deletion" component={DataDeletionPage} exact />

            <Route path="/referral" component={ReferralPage} exact />
            {/* <Route path="/referral-redirect" component={ReferralRedirectPage} exact /> */}
            
            
            
            </Switch>
          </Suspense>
        </main>
)
}
import axios from "axios";
import config from "../config/properties";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAILURE = "LOGIN_FAILURE";

const VERIFYOTP_SUCCESS = "VERIFYOTP_SUCCESS";
const VERIFYOTP_FAILURE = "VERIFYOTP_FAILURE";

const CREATEACCOUNT_SUCCESS = "CREATEACCOUNT_SUCCESS";
const CREATEACCOUNT_FAILURE = "CREATEACCOUNT_FAILURE";

// export function loginOtp(phoneno,countrycode) {
//   try {

//     return async () => {

//       return await axios.post(config.base_url + "customer/login-otp",
//         {
//           "mobile_number":phoneno,
//           "country_code":countrycode,
//           "userType":"customer"

//         }
//         // phoneNo
//       )
//     }

//   }
//   catch (e) {

//   }
// }

export function loginOtp(allLoginvalues) {
  return async (dispatch) => {
    dispatch(loginRequest("Request"));
    function onSuccess(success) {
      dispatch({ type: LOGIN_SUCCESS, success });
      return success;
    }

    function onError(error) {
      dispatch({ type: LOGIN_FAILURE, error });
      return error;
    }

    try {
      let payload = {
        ...allLoginvalues,
      };
      if (localStorage.getItem("utm_source")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_source: localStorage.getItem("utm_source"),
        };
      }
      if (localStorage.getItem("utm_medium")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_medium: localStorage.getItem("utm_medium"),
        };
      }
      if (localStorage.getItem("utm_campaign")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_campaign: localStorage.getItem("utm_campaign"),
        };
      }
      if (localStorage.getItem("utm_term")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_term: localStorage.getItem("utm_term"),
        };
      }
      if (localStorage.getItem("utm_content")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_content: localStorage.getItem("utm_content"),
        };
      }
      if (localStorage.getItem("influencer")) {
        payload = {
          ...payload,
          influencer: localStorage.getItem("influencer"),
        };
      }

      const success = await axios.post(
        config.base_url + "customer/login-otp",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            type: "web",
            origin: "https://niulihome.com",
          },
        }
      );
      console.log(success);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
}

export function verifyOtp(phoneno, otpvalue, influencer) {
  return async (dispatch) => {
    dispatch(verifyotpRequest("Request"));
    function onSuccess(success) {
      dispatch({ type: VERIFYOTP_SUCCESS, success });
      return success;
    }

    function onError(error) {
      dispatch({ type: VERIFYOTP_FAILURE, error });
      return error;
    }

    try {
      let payload = {
        mobile_number: phoneno,
        mobileOTP: otpvalue,
        deviceType: "web",
      };
      if (localStorage.getItem("utm_source")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_source: localStorage.getItem("utm_source"),
        };
      }
      if (localStorage.getItem("utm_medium")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_medium: localStorage.getItem("utm_medium"),
        };
      }
      if (localStorage.getItem("utm_campaign")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_campaign: localStorage.getItem("utm_campaign"),
        };
      }
      if (localStorage.getItem("utm_term")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_term: localStorage.getItem("utm_term"),
        };
      }
      if (localStorage.getItem("utm_content")) {
        payload.utm_params = {
          ...payload.utm_params,
          utm_content: localStorage.getItem("utm_content"),
        };
      }
      if (localStorage.getItem("influencer")) {
        payload = {
          ...payload,
          influencer: localStorage.getItem("influencer"),
        };
      }
      const success = await axios.post(
        config.base_url + "customer/verify-otp",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
}

// export function signup(allsignupvalues) {
//   try {

//     return async () => {

//       return await axios.post(config.base_url + "customer/signup",allsignupvalues

//       )
//     }

//   }
//   catch (e) {

//   }
// }

export function signup(allsignupvalues) {
  return async (dispatch) => {
    dispatch(createaccountRequest("Request"));
    function onSuccess(success) {
      dispatch({ type: CREATEACCOUNT_SUCCESS, success });
      return success;
    }

    function onError(error) {
      dispatch({ type: CREATEACCOUNT_FAILURE, error });
      return error;
    }

    try {
      const success = await axios.post(
        config.base_url + "customer/signup",
        allsignupvalues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(success);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
}

export function campaignSignUp(allsignupvalues) {
  return async (dispatch) => {
    // dispatch(loginRequest("Request"));
    function onSuccess(success) {
      dispatch({ type: VERIFYOTP_SUCCESS, success });
      return success;
    }

    function onError(error) {
      dispatch({ type: VERIFYOTP_FAILURE, error });
      return error;
    }

    try {
      const success = await axios.post(
        config.base_url + "customer/landingRegistration",
        allsignupvalues,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(success);
      return onSuccess(success);
    } catch (error) {
      return onError(error);
    }
  };
}

export const logoutApp = (param) => {
  return async () => {
    try {
      const logoutAppRequest = await axios.post(
        config.base_url + "customer/logout",
        param,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (logoutAppRequest.data.status_code === 200) {
        return logoutAppRequest;
      }
    } catch (e) {}
  };
};

export const getallcountries = () => {
  return async () => {
    try {
      const countryresponse = await axios.post(
        config.base_url + "customer/getAllConfigurations",
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (countryresponse.data.status_code === 200) {
        return countryresponse;
      }
    } catch (e) {}
  };
};

export const updateDeviceToken = (obj) => {
  return async () => {
    try {
      const updateDeviceTokenResponse = await axios.post(
        config.base_url + "customer/updateDeviceToken",
        obj,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return updateDeviceTokenResponse;
    } catch (e) {}
  };
};

export const recoverAccountR = (param) => {
  return async () => {
    try {
      const RecoverAccRequest = await axios.post(
        config.base_url + "customer/recoverAcount",
        param,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return RecoverAccRequest;
    } catch (e) {}
  };
};

export const deleteAccount = (param) => {
  return async () => {
    try {
      const delAccount = await axios.post(
        config.base_url + "customer/deleteAcount",
        param,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return delAccount;
    } catch (e) {
      return e.response;
    }
  };
};

export const getCityFromLatAndLong = (latitude, longitude) => {
  return async () => {
    try {
      const mapsResponse = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude},${longitude}&key=AIzaSyBMJPnpsiyIhXFZJztASrPPSxjZzeRMnTw`
      );
      return mapsResponse;
    } catch (e) {
      return e.response;
    }
  };
};

const loginRequest = (response) => ({
  type: "LOGIN_REQUESTING",
  response,
});

const verifyotpRequest = (response) => ({
  type: "VERIFYOTP_REQUESTING",
  response,
});

const createaccountRequest = (response) => ({
  type: "CREATEACCOUNT_REQUESTING",
  response,
});

export const updatedPrivacyPolicy = {
  en: {
    ppTitle: "Privacy Policy",
    ppSubTitle:
      "شركة نول الشرق التجارية – سياسة حماية البيانات الشخصية ('الخصوصية')",
    ppSection1Title: "المقدمة",
    ppSection1Content1:
      "تلتزم شركة نول الشرق التجارية  (Niuli)  التي يشار إليها فيما يلي بــ ('الشركة') بالحفاظ على سرية وخصوصية هذه البيانات واستخدامها للوصول إلى المستوى المأمول في تقديم الخدمة اللازمة للمستخدم بما يتوافق أحكام الأنظمة المعمول بها في المملكة العربية السعودية.",
    ppSection1UL1:
      "نظام حماية البيانات الشخصية الصادر بالمرسوم الملكي رقم (م/19) وتاريخ 1443/2/9هـ والمعدل بموجب المرسوم الملكي رقم (م/148) وتاريخ 5 /9/ 1444هـ، ",
    ppSection1UL2:
      "لائحة التنفيذية الصادرة عن الهيئة السعودية للبيانات والذكاء الاصطناعي (سدايا).",
    ppSection1Content2:
      "تحدد ('الخصوصية')  لدينا الغرض من جمع البيانات الشخصية، وطبيعة البيانات التي يتم جمعها، والوسائل المستخدمة لجمع البيانات الشخصية، ومعالجة وتخزين واتلاف البيانات الشخصية، والمعلومات المتعلقة بحقوق مالك للبيانات الشخصية/ صاحب البيانات وكيفية ممارسته لحقوقه.",
    ppSection1Content3:
      "إن استخدام هذا ('الموقع/التطبيق') تعني موافقة المستفيد الصريحة على جمع ونقل ومعالجة واستخدام وتخزين أي معلومات شخصية بموجب سياسة الخصوصية الواردة في هذه الوثيقة، إذا كان المستفيد لا يوافق على أي من الشروط والأحكام المنصوص عليها في سياسة الخصوصية، أو للمزيد من المعلومات، يرجى التواصل مع جهة التحكم مباشرة من خلال البريد الالكتروني أدناه و سوف تُقدم له المساعدة اللازمة.  إن دخول المستخدم أو استخدامه للموقع/التطبيق يمنح شركة نول الشرق التجارية ترخيصًا دائماً وغير حصري في كافة أنحاء العالم دون رسوم أو مقابل مادي لإستخدام معلومات المستفيد الشخصية للأغراض المنصوص عليها في هذه الوثيقة.",
    ppSection2Title: "التفسيرات والتعاريف",
    ppSection2UL1title: "المصلحة الفعلية:",
    ppSection2UL1Content:
      " تشير إلى أي مصلحة معنوية أو مادية لـ (مالك/صاحب البيانات) والتي ترتبط ارتباطًا مباشرًا بغرض معالجة البيانات الشخصية، وكانت تلك المعالجة ضرورية لتحقيق هذا الغرض.",
  },
  ar: {
    ppTitle: "Privacy Policy",
    ppSubTitle:
      "شركة نول الشرق التجارية – سياسة حماية البيانات الشخصية ('الخصوصية')",
    ppSection1Title: "المقدمة",
    ppSection1Content1:
      "تلتزم شركة نول الشرق التجارية  (Niuli)  التي يشار إليها فيما يلي بــ ('الشركة') بالحفاظ على سرية وخصوصية هذه البيانات واستخدامها للوصول إلى المستوى المأمول في تقديم الخدمة اللازمة للمستخدم بما يتوافق أحكام الأنظمة المعمول بها في المملكة العربية السعودية.",
    ppSection1UL1:
      "نظام حماية البيانات الشخصية الصادر بالمرسوم الملكي رقم (م/19) وتاريخ 1443/2/9هـ والمعدل بموجب المرسوم الملكي رقم (م/148) وتاريخ 5 /9/ 1444هـ، ",
    ppSection1UL2:
      "لائحة التنفيذية الصادرة عن الهيئة السعودية للبيانات والذكاء الاصطناعي (سدايا).",
    ppSection1Content2:
      "تحدد ('الخصوصية')  لدينا الغرض من جمع البيانات الشخصية، وطبيعة البيانات التي يتم جمعها، والوسائل المستخدمة لجمع البيانات الشخصية، ومعالجة وتخزين واتلاف البيانات الشخصية، والمعلومات المتعلقة بحقوق مالك للبيانات الشخصية/ صاحب البيانات وكيفية ممارسته لحقوقه.",
    ppSection1Content3:
      "إن استخدام هذا ('الموقع/التطبيق') تعني موافقة المستفيد الصريحة على جمع ونقل ومعالجة واستخدام وتخزين أي معلومات شخصية بموجب سياسة الخصوصية الواردة في هذه الوثيقة، إذا كان المستفيد لا يوافق على أي من الشروط والأحكام المنصوص عليها في سياسة الخصوصية، أو للمزيد من المعلومات، يرجى التواصل مع جهة التحكم مباشرة من خلال البريد الالكتروني أدناه و سوف تُقدم له المساعدة اللازمة.  إن دخول المستخدم أو استخدامه للموقع/التطبيق يمنح شركة نول الشرق التجارية ترخيصًا دائماً وغير حصري في كافة أنحاء العالم دون رسوم أو مقابل مادي لإستخدام معلومات المستفيد الشخصية للأغراض المنصوص عليها في هذه الوثيقة.",
    ppSection2Title: "التفسيرات والتعاريف",
    ppSection2UL1title: "المصلحة الفعلية:",
    ppSection2UL1Content:
      " تشير إلى أي مصلحة معنوية أو مادية لـ (مالك/صاحب البيانات) والتي ترتبط ارتباطًا مباشرًا بغرض معالجة البيانات الشخصية، وكانت تلك المعالجة ضرورية لتحقيق هذا الغرض.",
    ppSection2UL2title: "جهة التحكم:",
    ppSection2UL2Content:
      " يعني شركة نول الشرق التجارية  وشركاتها القابضة والشركات التابعة والشركات الفرعية لها وأي أشخاص طبيعيين أو اعتباريين مرتبطين بـ 'الشركة' الذين يحددون الغرض من وطريقة جمع البيانات الشخصية ومعالجتها.",
    ppSection2UL3title: "مالك/صاحب البيانات:",
    ppSection2UL3Content:
      " تشير إلى الفرد الذي تتعلق به البيانات الشخصية والتي تقوم جهة التحكم بتخزينها ومعالجتها.",
    ppSection2UL4title: "الجمع:",
    ppSection2UL4Content:
      " يعني جمع البيانات الشخصية بواسطة 'جهة التحكم' وفقًا لأحكام النظام إما منك بصفتك 'صاحب للبيانات' مباشرةً، أو مِن ممثلك، أو من أي وصي نظامي عليك كصاحب للبيانات، أو أي طرف آخر.",
    ppSection2UL5title: "الإفصاح:",
    ppSection2UL5Content:
      " يعني عملية تمكين أي شخص آخر غير جهة التحكم والمعالج، وفق مقتضى الحال، من الوصول إلى البيانات الشخصية أو جمعها أو استخدامها بأي وسيلة كانت ولأي غرض كان.",
    ppSection2UL6title: "الإتلاف:",
    ppSection2UL6Content:
      "  يشير إلى أي إجراءٍ يتم اتخاذه بشأن البيانات الشخصية بحيث يجعلها غير قابلة للقراءة وغير قابلة للاسترجاع، أو يجعل من المستحيل تحديد هوية صاحب البيانات/ مالك البيانات الشخصية المعنية.",
    ppSection2UL7title: "الموافقة الصريحة:",
    ppSection2UL7Content:
      " تعني الموافقة المباشرة والصريحة المقدمة من مالك البيانات الشخصية/ صاحب البيانات بأي شكل يشير بوضوح إلى قبول صاحب البيانات لمعالجة بياناته الشخصية كصاحب لها بطريقة لا يمكن تفسيرها بغير ذلك، كما يمكن إثبات الحصول عليها.",
    ppSection2UL8title: "المصلحة المشروعة:",
    ppSection2UL8Content:
      " تشير إلى أي مصلحة ضرورية لـ 'جهة التحكم' تتطلب معالجة البيانات الشخصية لغرض محدد، بشرط ألا يؤثر ذلك سلبًا على حقوق ومصالح مالك البيانات الشخصية/ صاحب للبيانات.",
    ppSection2UL9title: "البيانات الشخصية:",
    ppSection2UL9Content:
      " تعني أي بيانات، بغض النظر عن مصدرها أو شكلها، والتي قد تؤدي إلى التعرف على صاحب البيانات كفرد على وجه التحديد، أو قد تتيح سواء بشكل مباشر أو غير مباشر التعرف عليه كفرد بما في ذلك بالاسم ورقم التعريف الشخصي والسجلات والأصول الشخصية وأرقام البنوك وبطاقات الائتمان والصور ومقاطع الفيديو الخاصة بك كفرد، وأي بيانات أخرى ذات طبيعة شخصية.",
    ppSection2UL10title: "المعالج:",
    ppSection2UL10Content:
      " يعني أي كيان عام أو شخص طبيعي أو شخص اعتباري خاص  والذي يعالج البيانات الشخصية لصالح ونيابةً عن شركة احمد عبدالله حمد العيسى ('جهة التحكم').",
    ppSection2UL11title: "المُعالَجة:",
    ppSection2UL11Content:
      " تعني أي عملية تجري على البيانات الشخصية بأي وسيلة، سواء كانت يدوية أو آلية وبما في ذلك جمع وتسجيل وحفظ وفهرسة وتنظيم وتنسيق وتخزين وتعديل وتحديث ودمج وتوحيد واسترجاع واستخدام وكشف وإرسال ونشر ومشاركة البيانات الشخصية وقرنها وحظرها ومحوها وتدميرها.",
    ppSection2UL12title: "النقل:",
    ppSection2UL12Content:
      " يعني نقل البيانات الشخصية من مكان إلى آخر لغرض المعالجة.",
    ppSection2UL13title: "النشر:",
    ppSection2UL13Content:
      "  بث أي من البيانـات الشخصية عبـر وسيلـة نشـر مقـروءة أو مسموعـة أو مرئية، أو إتاحتها.",
    ppSection2Content1:
      "تفسر المصطلحات الواردة في هذه السياسة 'سياسة حماية البيانات الشخصية والخصوصية' وفقًا للمعاني المنصوص عليها في نظام حماية البيانات الشخصية.",
    ppSection3Title: "طبيعة البيانات الشخصية التي سيتم جمعها",
    ppSection3Content:
      "قد تتضمن البيانات، على سبيل المثال لا الحصر، (الاسم، عنوان البريد الإلكتروني، رقم الهاتف، اسم المستخدم وكلمة المرور، بيانات الدفع أو المعلومات المصرفية والتوقيع، السجل التجاري، رقم المتجر، اسم المتجر، تصاريح المتجر، الموقع الجغرافي، الأبعاد، التصاميم.)",
    ppSection4Title: "طرق جمع البيانات",
    ppSection4Content1:
      "المعلومات التي يقدمها مالك البيانات إلى شركة نول الشرق التجارية ، مثل المعلومات التي يقدمها المستخدم عندما يقوم بإنشاء الحساب الخاص به على موقع/ تطبيق الشركة، أو يقدمها للشركة كتابةً او شفيهاً للاستفادة من الخدمات التي تقدمها الشركة او  تعبئة النماذج على الموقع الإلكتروني أو الفروع.",
    ppSection4Content2:
      "المعلومات التي تم الحصول عليها من الشركات التابعة والشركات الفرعية لشركة نول الشرق التجارية  وأي أشخاص طبيعيين أو اعتباريين مرتبطين بموجب سياسات الإفصاح بين الجهات. ",
    ppSection4Content3:
      " المعلومات الناتجة عن استخدام الخدمات، مثل الموقع الجغرافي ومعلومات الجهاز.",
    ppSection4Content4:
      "قد تستخدم الشركة مزود خدمة خارجي للمساعدة في تأمين بعض خصائص ومزايا الخدمات. سيتلقى مزود الخدمة الخاص بالشركة معلومات المستفيد نيابةً عن الشركة، ولن يُسمح له باستخدامها لأي غرض آخر.",
    ppSection4Content5:
      "يتم جمع البيانات الشخصية بشكل غير مباشر من خلال استمارات الاتصال التي يتم تعبئتها بواسطة مالك البيانات شخصيًا أو عبر الإنترنت.",
    ppSection5Title: "الغرض من جمع البيانات الشخصية",
    ppSection5UL1Title: "الأغراض التجارية:",
    ppSection5UL1MainContent:
      " تستخدم الشركة معلومات الاتصال والمعلومات المالية والتجارية الخاصة بالمستفيد من أجل:",
    ppSection5UL1SubContent1:
      "استلام أو معالجة أو تأكيد أو إرسال أو تتبع أوامر الشراء الخاصة بالمستفيد.",
    ppSection5UL1SubContent2: "التواصل مع المستفيد بشأن أمر الشراء الخاص به.",
    ppSection5UL1SubContent3: "تسليم أوامر الشراء الى المستفيد.",
    ppSection5UL1SubContent4:
      "معالجة أي اشتراك أو تسجيل يقوم به المستفيد لإحدى خدمات الشركة.",
    ppSection5UL1SubContent5:
      "الحفاظ على 'قائمة الرغبات' الخاصة بالمستفيد من خلال سجله.",
    ppSection5UL2Title: "الأغراض التحليلية: ",
    ppSection5UL2MainContent:
      "تستخدم الشركة المعلومات الأخرى التي تكشف عن هوية المستفيد ونشاطه على الإنترنت وتاريخ التصفح والمعلومات التجارية وبيانات تحديد الموقع الجغرافي الخاصة به لتحليل التفضيلات والاتجاهات والإحصائيات. وتستخدم الشركة أحياناً أدوات تحليلات خارجية للمساعدة في مهام التحليل.",
    ppSection5UL3Title: "الأغراض التسويقية والترويجية:",
    ppSection5UL3MainContent:
      " تستخدم الشركة معلومات الاتصال، أو المعلومات التجارية، أو نشاط المستفيد على الإنترنت أو أي نشاط إلكتروني آخر، من أجل فهم بحثك على المواقع الإلكترونية.",
    ppSection5UL4Title: "صيانة وتحسين الخدمات والموقع الإلكتروني:",
    ppSection5UL4MainContent:
      " قد تستخدم الشركة معلومات الاتصال والمعلومات التجارية الخاصة بالمستفيد، ونشاطه على الإنترنت، وتاريخ التصفح لتطوير الميزات والوظائف على المواقع الإلكترونية.",
    ppSection5UL5Title: "أغراض خدمة العميل: ",
    ppSection5UL5MainContent:
      " قد يستلزم جمع البيانات لأغراض متابعة الاستفسارات أو الاقتراحات أو الشكاوى المقدمة من قِبل المستخدم.",
    ppSection5UL6Title: "الأمن ومنع الاحتيال:",
    ppSection5UL6MainContent:
      " تستخدم الشركة معلومات الاتصال الخاصة بالمستفيد، والمعلومات الأخرى التي تحدد الهوية، والمعلومات التجارية، والمعلومات المالية، وبيانات الموقع الجغرافي، ونشاط الإنترنت، وتاريخ التصفح، والاستدلالات لحماية هذا الموقع الإلكتروني، و الشركة، والآخرين، ومنع الاحتيال والسرقة وسوء السلوك.",
    ppSection6Title: "تخزين البيانات:",
    ppSection6Content1:
      "يتم تخزين البيانات التي نجمعها بشكل آمن على خوادم سحابية تقع في مراكز بيانات في المملكة العربية السعودية، البحرين، وألمانيا، وتتم معالجة البيانات مُشفّرة في هذه البلدان أو في دول أخرى خارج بلد إقامة صاحب البيانات. يتم نقل البيانات الشخصية وفقًا للقوانين واللوائح المعمول بها لضمان حماية البيانات بشكل كامل.",
    ppSection6Content2:
      "فيما يتعلق بالنقل إلى دول خارج نطاق بلد إقامة صاحب البيانات الشخصية، تلتزم الشركة باستخدام النصوص التعاقدية النموذجية وتطبيق قوانين الحماية اللازمة في الدول التي لا تصنف على أنها توفر مستوى كافٍ من حماية البيانات الشخصية.",
    ppSection7Title: " مشاركة البيانات في الحالات التالية:",
    ppSection7MainContent:
      "لا تقوم الشركة ببيع / مشاركة البيانات الشخصية إلى أطراف ثالثة،  ولكنها تتبادل بعض من معلومات المستفيدين المختارة مع أطراف ثالثة و هي:",
    ppSection7SubContent1:
      "مجموعة الشركات و العلامات التجارية التابعة لشركة نول الشرق التجارية .",
    ppSection7SubContent2: "الشركات المانحة لحقوق الامتياز الحصرية",
    ppSection7SubContent3:
      "شركات أخرى مثل مقدمي خدمات الدفع وخدمات المخازن وشركات التوصيل بهدف إتمام عملية الدفع وإدارة طلب المستفيد مما يتضمن توصيل الطلب واسترجاعه",
    ppSection7SubContent4:
      ".الشركات التي تشتري علامة/علامات تجارية تابعة لشركة نول الشرق التجارية  ليتمكنوا من تقديم الخدمات المناسبة للمستفيدين.",
    ppSection7SubContent5:
      "قواعد بيانات الطرف الثالث التي تشترك فيها لشركة نول الشرق التجارية  و / أو واحدة من علاماتها التجارية.",
    ppSection7SubContent6:
      "الهيئات الحكومية أو السلطات الأخرى عند اللزوم التزاماً بالأحكام والقوانين، أو للمساعدة في تطبيق القانون، أو لحماية حقوق الملكية أو أي حقوق أخرى لشركة احمد عبدالله حمد العيسى. ",
    ppSection7SubContent7:
      "الوكالات التي تساعد شركة نول الشرق التجارية  في تحليل البيانات و جمع إحصاءات عن عدد زوار الموقع والمبيعات والمعلومات التجارية الأخرى التي تمكن الشركة من تخصيص الخدمات التي يتم تقديمها للمستفيدين.",
    ppSection8Title: "الاحتفاظ بالبيانات الشخصية",
    ppSection8Content:
      "ستحتفظ الشركة بالبيانات الشخصية فقط للمدة الضرورية للأغراض المنصوص عليها في 'سياسة حماية البيانات الشخصية والخصوصية' هذه. سوف تحتفظ بها وتستخدمها بالقدر اللازم للامتثال للإلتزامات النظامية، وحل النزاعات، وإنفاذ الاتفاقيات و السياسات النظامية التي تخضع لتعليمات الجهات التنظيمية، مثل الهيئة السعودية للبيانات والذكاء الاصطناعي ('سدايا') وأي هيئة حكومية أخرى، وللامتثال لغيرها من القواعد واللوائح المعمول بها داخل المملكة العربية السعودية.",
    ppSection9Title: "نقل و معالجة بياناتك الشخصية",
    ppSection9Content1:
      "تتم معالجة المعلومات، بما في ذلك البيانات الشخصية من خلال فريق معالجة داخلي في [ المملكة العربية السعودي] وفريق معالجة خارجي في [باكستان ]. ويعني ذلك أن فريق المعالجة يستطيع الوصول الى الخوادم التي تحفظ البيانات، ومما يعني أنه قد يتم نقل بياناتك الشخصية والاحتفاظ بها لمعاجلتها  [خارج المملكة العربية السعودية]",
    ppSection9Content2:
      "تدل موافقة المستفيد على سياسة حماية البيانات الشخصية والخصوصية هذه، متبوعةً بتقديمه للبيانات الشخصية/ المعلومات الشخصية المطلوبة، على موافقته على نقل مثل هذه البيانات.",
    ppSection9Content3:
      "ستتخذ شركة نول الشرق التجارية  جميع الخطوات الضرورية بالقدر المعقول لضمان التعامل مع البيانات الشخصية المقدمة إليها على نحو آمن و وفقًا لسياسة حماية البيانات الشخصية والخصوصية هذه، و لن يتم نقل هذه البيانات الشخصية أو مشاركتها إلى أي منظمة أو دولة ما لم تكن هناك ضوابط كافية موضوعة لضمان أمن البيانات والمعلومات الشخصية الخاصة بالمستفيد.",
    ppSection10Title: "حقوق صاحب البيانات الشخصية:",
    ppSection10UL1Title: "الحق في العلم: ",
    ppSection10UL1Content:
      " يحق لصاحب البيانات الشخصية معرفة طرق جمعنا لبياناته والأساس النظامي لجمعها ومعالجتها، وكيفية معالجتها وحفظها وإتلافها ومع من ستتم مشاركتها ويمكنك الاطلاع على كافة التفاصيل من خلال سياسة الخصوصية -هذه السياسة- أو يمكن التواصل مع جهة التحكم عبر البيانات الموضحة في البند ('16') وسيتم تزويده بها –بلا مقابل مادي– خلال مدة لا تتجاوز [ثلاثين يوماً]، عن طريق البريد الإلكتروني.",
    ppSection10UL2Title: "الحق في الوصول إلى البيانات الشخصية: ",
    ppSection10UL2Content:
      " يحق لصاحب البيانات الشخصية أن يطلب نسخة من بياناته الشخصية، وذلك عن طريق البريد الإلكتروني الموضح في البند ('16')، وسيتم تزويده بها –بلا مقابل مادي– خلال مدة لا تتجاوز [ثلاثين يوماً]، عن طريق البريد الإلكتروني.",
    ppSection10UL3Title: "الحق في تصحيح البيانات الشخصية: ",
    ppSection10UL3Content:
      "يحق لصاحب البيانات الشخصية أن يطلب تصحيح بياناته الشخصية التي يرى أنها غير دقيقة أو غير صحيحة أو غير مكتملة، وذلك عن طريق البريد الإلكتروني الموضح في البند '(16')، وستتم مراجعتها وتحديثها مدة لا تتجاوز [ثلاثين يوماً]، وسيتم إشعار صاحب البيانات الشخصية بذلك عن طريق البريد الإلكتروني.",
    ppSection10UL4Title: "الحق في إتلاف البيانات الشخصية: ",
    ppSection10UL4Content:
      " يحق لصاحب البيانات الشخصية أن يطلب إتلاف بياناته الشخصية في ظروف معينة ما لم يكن هناك نص نظامي يحدد مدة معينة للاحتفاظ بالبيانات أو متطلبات تعاقدية تمنع إتلاف البيانات.",
    ppSection10UL5Title:
      "الحق في الرجوع عن الموافقة على معالجة البيانات الشخصية: ",
    ppSection10UL5Content:
      "يمكن لصاحب البيانات الشخصية الرجوع عن الموافقة على معالجة بياناته الشخصية – في أي وقت– ما لم تكن هناك أغراض مشروعة تتطلب عكس ذلك.",
    ppSection10UL6Title: "",
    ppSection10UL6Content:
      "تحتفظ الشركة بالحق في تمديد الفترة المذكورة أعلاه إذا استلزم التنفيذ جهدًا إضافيًا غير متوقع أو غير عادي، أو في حال تلقي الشركة طلبات متعددة من صاحب البيانات الشخصية، على أن لا يتجاوز التمديد مدة ثلاثين يومًا إضافية.",
    ppSection11Title: "الإفصاح عن بياناتك الشخصية",
    ppSection11Content:
      "قد تفصح  شركة نول الشرق التجارية ، تحت ظروف معينة، الإفصاح عن البيانات الشخصية إن كان ذلك مطلوبًا بموجب النظام أو استجابةً لطلبات صحيحة من الهيئات العامة (مثل المحاكم أو الجهات الحكومية الأخرى). لذلك يجوز للشركة الإفصاح عن البيانات الشخصية عند اعتقادها بحسن نية أن هذا الإجراء إلزامي من أجل:",
    ppSection11UL1: " مراعاة حماية سيادة المملكة العربية السعودية.",
    ppSection11UL2: " الامتثال لإلتزام نظامي.",
    ppSection11UL3: " حماية والدفاع عن حقوق أو ملكية شركة نول الشرق التجارية.",
    ppSection11UL4:
      " منع أي مخالفات محتملة أو التحقيق فيها فيما يتعلق بالمنتجات/ الخدمات.",
    ppSection11UL5:
      " حماية السلامة الشخصية لعملاء شركة نول الشرق التجارية أو الجمهور بشكل عام.",
    ppSection11UL6: " الحماية من المسؤولية النظامية.",
    ppSection11UL7: "أي أسباب أخرى تراها الشركة ضرورية. ",
    ppSection12Title: "أمن البيانات الشخصية",
    ppSection12Content:
      "يعد أمن البيانات الشخصية أولوية قصوى بالنسبة لشركة نول الشرق التجارية ، وتتم حمايته عبر وسائل الحماية المادية والفنية والإدارية التي تتوافق مع اللوائح السارية. ستبذل الشركة جهودًا وإجراءات معقولة لتأمين البيانات الشخصية لعملائنا ضد سوء الاستخدام والفقدان، بالإضافة إلى الوصول غير المصرّح به أو التعديل أو الإفصاح عنها. تُطبق الشركة إجراءات أمنية لضمان حماية البيانات الشخصية والالتزام بمعايير التشفير القياسية لنقل البيانات. تقوم الشركة بالتأكد من أن موظفينا والشركات التابعة لنا على دراية بأهمية حماية البيانات الشخصية والحفاظ على سريتها.",
    ppSection13Title: "حق الأوصياء النظاميين",
    ppSection13Content1:
      "لا تقوم شركة نول الشرق التجارية  بجمع بيانات شخصية من أي شخص يقل عمره عن 18 عامًا، دون موافقة الوالدين/ ولي الوصي النظامي. إن كنت أحد الوالدين/ الوصي النظامي وكنت على علم بأن طفلك قد زودنا ببيانات شخصية، فيرجى الاتصال بالشركة.",
    ppSection13Content2:
      "إذا علمت الشركة أنها قامت بجمع بيانات شخصية من أي شخص يقل عمره عن 18 عامًا دون التحقق من موافقة الوالدين، سيكون لزاماً على الشركة اتخاذ الخطوات اللازمة لإزالة تلك المعلومات من قاعدة البيانات الخاصة بها.",
    ppSection14Title: " الروابط الخارجية:",
    ppSection14Content:
      "قد يتم توفير روابط لمواقع إلكترونية أخرى على التطبيق/ الموقع التابع لشركة نول الشرق التجارية  مراعاة لاحتياجات المستخدم ولا تعتبر الشركة مسؤولة عن أي محتويات موجودة على تلك المواقع الإلكترونية ولا عن استخدام أي شخص لها أو عن عملها بشكل مناسب، أو عمّا قد ينشأ عن استخدامها من مشكلات، ويكون المستخدم مسؤولاً عن كافة ما يقوم به من تصرفات أثناء استخدامه لأي من المواقع الإلكترونية التي يقوم بزيارتها من خلال تلك الروابط الموجودة على هذا التطبيق.",
    ppSection15Title: " تعديل/تحديث سياسة الخصوصية",
    ppSection15UL1:
      "تم إجراء آخر تحديث على سياسة الخصوصية بتاريخ  26 / /02 1446ه  1 سبتمبر 2024 ، أي تعديلات تطرأ (سواء بالإضافة / الحذف / التغيير) على هذه السياسة، يتم نشرها على الموقع الإلكتروني علىhttps://www.niulihome.com و /أو التطبيق. ونحن بدورنا نشجعك على البحث عن التحديثات والتغييرات التي تدخل على هذا البيان بالتحقق من تاريخ 'آخر مراجعة' عند دخولك إلى الموقع أو التطبيق.",
    ppSection15UL2:
      "تحتفظ الشركة بحــق إضافــة أو تغييــر أي من أحكام سياسة الخصوصية، وسوف تقــوم إدارة الموقع بإشــعار صاحب البيانات بذلك، ويحــق لإدارة الموقع إنهــاء حساب صاحب البيانات فــي حــال عــدم قبــوله أي تغييــر فــي سياسة الخصوصية. ",
    ppSection15UL3:
      "إن اللغة العربية هي اللغة المعتمدة في تطبيق الأحكام والشروط، وفي حال نشأ خلاف في تفسير أي نص ورد بأي لغة أخرى فيؤخذ بالنص المكتوب باللغة العربية.",
    ppSection16Title: "الاتصال بالشركة",
    ppSection16Content:
      "إن كانت لدى المستفيد أي مخاوف أو ملاحظات بشأن معالجة البيانات الشخصية أو بشأن هذه السياسة، أو إذا كان المستفيد يريد من الشركة أن تقوم بتغيير المعلومات أو التفضيلات التي قدمها للشركة كمستفيد، فيرجى التواصل مع الشركة عبر البريد الإلكتروني على info@niulihome.com، أو عنوان المقر الرئيسي للشركة: شركة وقف الراجحي الصناعية والتجارية. 7376 طريق الملك عبد العزيز - حي الغدير، وحدة رقم 21، الرياض 13311 - 4756، المملكة العربية السعودية.",
    ppSection17Title: "الاتفاق الكامل",
    ppSection17Content:
      "تشكل هذه الاتفاقية الاتفاق الكامل بين المستخدم وبين شركة نول الشرق التجارية  فيما يتعلق بخصوصية المستخدم.",
    ppSection18Title: "قبول الاتفاقية",
    ppSection18Content:
      "باستخدام موقع/تطبيق/خدمات شركة نول الشرق التجارية  ، فإن المستخدم يوافق على شروط وأحكام هذه الاتفاقية.",
  },
};
